import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function HomePage() {
  const [vList, setVList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "八木歌单";
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://v1.vup.name/api/v1/vlist');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVList(data);
      } catch (error) {
        console.error('Fetch error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gray-100 p-5 w-full h-screen">
      <div className="max-w-6xl mx-auto grid grid-cols-1 gap-4">
        {loading && <div>Loading...</div>}
        {!loading && vList.map((item) => (
          <Link to={`/${item.uid}`} key={item.uid} className="relative card bg-base-100 shadow-xl h-64 rounded-lg mb-5 overflow-hidden">
            <div className="absolute inset-0 bg-cover bg-center filter" style={{ backgroundImage: `url(https://v1.vup.name/proxy/avatar?url=${item.background_image_url})` }}></div>
            <div className="relative z-10 p-5 backdrop-blur-xl bg-gray-400/20">
              <h2 className="card-title text-white text-xl font-bold">{item.name}</h2>
              <p className="text-white">{item.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
