import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGuitar, faBoxOpen, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const Info = () => {
  let { uid } = useParams();
  const [broadcaster, setBroadcaster] = useState(null);

  useEffect(() => {
    fetch(`https://v1.vup.name/api/v1/songlist?uid=${uid}`)
      .then((response) => response.json())
      .then((data) => {
        setBroadcaster(data.broadcaster);
        if (data.broadcaster && data.broadcaster.name) {
          document.title = data.broadcaster.name;
        }
      });
  }, [uid]);

  return (
    <div className="relative h-screen">
      <div className={`absolute inset-0 bg-cover bg-center ${broadcaster ? 'bg-fixed' : ''}`} style={{ backgroundImage: broadcaster ? `url(https://v1.vup.name/proxy/avatar?url=${broadcaster.background_image_url})` : '' }}>
      </div>
      <div className="absolute inset-0 bg-white/5 backdrop-blur-sm"></div>
      <div className="relative p-4 h-full flex flex-col items-center justify-center">
        {broadcaster && (
          <div className="card lg:card-side shadow-xl mt-4 mx-auto p-5 items-center text-center bg-black/10 backdrop-blur-2xl w-11/12 lg:w-2/5">
            <div className="flex items-center h-32">
              <div className="w-32 mask mask-squircle">
                <img src={`https://v1.vup.name/proxy/avatar?url=${broadcaster.profile_url}`} alt="Broadcaster" />
              </div>
            </div>
            <div className="card-body items-center text-center">
              <h2 className="card-title text-white">{broadcaster.name}</h2>
              <p className="font-bold text-white">{broadcaster.description}</p>
            </div>
          </div>
        )}

        <div className="w-11/12 lg:w-2/5 mx-auto flex flex-col lg:flex-row lg:space-x-4 mt-4">
          <a href={`/#/${uid}/song`} className="card shadow-xl hover:bg-black/20 flex flex-col items-center justify-center bg-black/10 backdrop-blur-xl p-8 mb-4 lg:w-1/3">
            <FontAwesomeIcon icon={faGuitar} size="2x" className="text-white" shake/>
            <p className="text-white mt-2 font-bold">歌单</p>
          </a>
          <a href={`/#/${uid}/verification`} className="card shadow-xl hover:bg-black/20 flex flex-col items-center justify-center bg-black/10 backdrop-blur-xl p-8 mb-4 lg:w-1/3">
            <FontAwesomeIcon icon={faLocationDot} size="2x" className="text-white" flip/>
            <p className="text-white mt-2 font-bold">舰长地址统计</p>
          </a>
          <a href="#" className="card shadow-xl hover:bg-black/20 flex flex-col items-center justify-center bg-black/10 backdrop-blur-xl p-8 mb-4 lg:w-1/3">
            <FontAwesomeIcon icon={faBoxOpen} size="2x" className="text-white" bounce/>
            <p className="text-white mt-2 font-bold">提问箱</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Info;
