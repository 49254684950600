import React, { useEffect, useState, useRef} from 'react';
import { useParams } from 'react-router-dom';

const SongPage = () => {
  let { uid } = useParams();
  const [broadcaster, setBroadcaster] = useState(null);
  const [songs, setSongs] = useState([]);
  const [originalSongs, setOriginalSongs] = useState([]); // 存储最初的歌曲列表
  const [searchTerm, setSearchTerm] = useState(''); // 搜索框的状态

  useEffect(() => {
    fetch(`https://v1.vup.name/api/v1/songlist?uid=${uid}`)
      .then((response) => response.json())
      .then((data) => {
        setBroadcaster(data.broadcaster);
        setSongs(data.songs);
        setOriginalSongs(data.songs); // 存储最初的歌曲列表
        if (data.broadcaster && data.broadcaster.name) {
          document.title = data.broadcaster.name; // 设置文档标题
        }
      });
  }, [uid]);

  // 搜索框变化的处理函数
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setSongs(originalSongs); // 显示原始歌曲列表
      return;
    }

    // 发起搜索请求
    fetch(`https://v1.vup.name/api/v1/search?uid=${uid}&q=${encodeURIComponent(searchTerm)}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setSongs(data); // 使用搜索结果更新歌曲列表
        } else {
          console.error('Search API did not return an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching search results:', error);
      });
  }, [searchTerm, uid, originalSongs]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [countdown, setCountdown] = useState(8); // 8秒倒计时
  const timerRef = useRef(null); 
  const handleRowClick = (songId) => {
    // 清除现有的定时器（如果有）
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  
    const selectedSong = songs.find(song => song.id === songId);
  
    // 复制文本到剪切板
    const copyToClipboard = text => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text).then(() => {
          console.log('复制成功！');
        }).catch(err => {
          console.error('复制失败：', err);
        });
      } else {
        // 备用方案: 使用 document.execCommand
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          let successful = document.execCommand('copy');
          if (successful) {
            console.log('复制成功！');
          } else {
            console.error('复制失败');
          }
        } catch (err) {
          console.error('备用复制方法失败', err);
        }
        document.body.removeChild(textArea);
      }
    };
    
  
    if (selectedSong) {
      const copyText = `点歌 ${selectedSong.title}`;
      copyToClipboard(copyText);
  
      // 如果歌曲是免费的，则只显示复制成功的消息
      if (selectedSong.is_paid === 0) {
        setPopupContent(<div><p className="text-lg font-semibold items-center text-center "><span className="text-red-500">{selectedSong.title}</span> 复制成功</p><p className="text-sm mt-2 items-center text-center text-lg font-bold">请将复制的内容发送到直播间</p></div>);
        setShowPopup(true);
        setCountdown(8);
  
        // 设置定时器
        timerRef.current = setInterval(() => {
          setCountdown(prevCountdown => {
            if (prevCountdown <= 1) {
              clearInterval(timerRef.current);
              setShowPopup(false);
              timerRef.current = null;
              return 0;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      } else {
        // 付费歌曲的处理逻辑
        const giftInfo = selectedSong?.gift_info;
        if (giftInfo) {
          // 构建付费歌曲的弹窗内容
          const popupContent = (
            <div className="flex flex-col items-center p-2 mt-2">
              <img src={`https://v1.vup.name/proxy/avatar?url=${giftInfo.webp}`} alt="Gift" className="w-24 h-24" />
              <p className="mt-2 text-lg font-semibold mb-2 mt-6"><span className="text-red-500">{selectedSong.title}</span>复制成功</p>
              <div className="flex items-center">
                <p className="text-lg font-bold mt-2">需打赏礼物</p>
                <div className="flex items-center">
                  <img src={`https://v1.vup.name/proxy/avatar?url=${giftInfo.webp}`} alt="Gift" className="w-10 h-10" />
                </div>
                <p className="text-lg font-bold mt-2"><span className="text-red-500">{giftInfo.gift_name}</span></p>
              </div>
              <p className="text-sm mt-2 font-bold">请在打赏礼物后将复制的内容发送到直播间</p>
            </div>
          );
  
          setPopupContent(popupContent);
          setShowPopup(true);
          setCountdown(8);
  
          // 设置新的定时器
          timerRef.current = setInterval(() => {
            setCountdown(prevCountdown => {
              if (prevCountdown <= 1) {
                clearInterval(timerRef.current);
                setShowPopup(false);
                timerRef.current = null;
                return 0;
              }
              return prevCountdown - 1;
            });
          }, 1000);
        }
      }
    }
  };
  
  
  

  return (
    <div className="relative w-full h-full">
    <div className="fixed top-0 left-0 w-full h-full bg-cover bg-center bg-no-repeat z-[-1]" style={{ backgroundImage: broadcaster ? `url(https://v1.vup.name/proxy/avatar?url=${broadcaster.background_image_url})` : '', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}></div>
    <div className="absolute inset-0 bg-white/10 backdrop-blur-sm bg-cover"></div>
      <div className="p-4 min-h-screen">
        {broadcaster && (
          <div className="card lg:card-side shadow-xl mt-4 mx-auto p-5 bg-black/20 backdrop-blur-[200px] max-w-6xl  lg:flex-row flex-col items-center text-center lg:text-left mx-auto w-full ">
            <div className="flex items-center h-32">
              <div className="w-32 mask mask-squircle">
                <img src={`https://v1.vup.name/proxy/avatar?url=${broadcaster.profile_url}`} alt="Broadcaster" />
              </div>
            </div>
            <div className="card-body">
              <h2 className="card-title text-2xl font-bold text-white whitespace-nowrap">{broadcaster.name}</h2>
              <p className="text-lg text-white font-bold whitespace-nowrap">{broadcaster.description}</p>
            </div>
          </div>
        )}
        <div className="max-w-6xl mx-auto my-4 ">
          <div className="flex items-center">
          <style>
            {`
              .placeholder-white::placeholder {
                color: white;
              }
            `}
          </style>
            <input type="text" className="input input-bordered shadow-xl w-full bg-black/20 border-0 text-white placeholder-white backdrop-blur-2xl" placeholder="搜索歌曲..." value={searchTerm} onChange={handleSearchChange} />
            <button className="btn shadow-xl bg-black/20 border-0 text-white ml-4 backdrop-blur-lg hover:bg-white/5" onClick={() => window.location.reload()}>
              重置
            </button>
          </div>
        </div>
        <div className="overflow-x-auto mt-2">
      <table className="table w-full rounded-xl backdrop-blur-[100px] p-4 max-w-6xl mx-auto shadow-xl mb-10 mt-2 bg-black/20 text-white text-lg font-bold text-center">
        <thead>
          <tr className="text-lg text-white">
            <th>歌曲名</th>
            <th>作者</th>
            <th>语言</th>
            <th>礼物信息</th>
          </tr>
        </thead>
        <tbody>
          {songs.map((song) => (
            <tr key={song.id} onClick={() => handleRowClick(song.id)}>
              <td>{song.title}</td>
              <td>{song.artist}</td>
              <td>{song.language}</td>
              <td>
              {song.gift_info ? (
                    <div className="flex md:flex-row flex-col items-center justify-center gap-3">
                      <div className="avatar">
                        <div className="mask mask-squircle w-12 h-12">
                          <img src={`https://v1.vup.name/proxy/avatar?url=${song.gift_info.webp}`} alt="Gift" />
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">{song.gift_info.gift_name}</div>
                        <div className="text-sm opacity-50">价值: {song.gift_info.gift_value} {song.gift_info.type}</div>
                      </div>
                    </div>
                  ) : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </div>

      {showPopup && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white/80 backdrop-blur-lg rounded-xl shadow-xl p-4 w-auto min-w-[350px]">
          <div className="relative">
            {popupContent}
            <div className="text-xs text-white-500 mt-4 text-center font-bold">{countdown} 秒后消失</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SongPage;
