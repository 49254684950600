import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const InYag = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const navigate = useNavigate();
  const [broadcaster, setBroadcaster] = useState(null);
  let { uid } = useParams();
  
  useEffect(() => {
    fetch(`https://v1.vup.name/api/v1/songlist?uid=${uid}`)
      .then((response) => response.json())
      .then((data) => {
        setBroadcaster(data.broadcaster);
        if (data.broadcaster && data.broadcaster.name) {
          document.title = data.broadcaster.name;
        }
      });
  }, [uid]);

  const handleSubmit = () => {
    navigate(`/${uid}/verificationcode?code=${verificationCode}`);
  };

  return (
    <div className="bg-gradient-to-r from-red-200 to-yellow-100 min-h-screen">
      <div className={`absolute inset-0 bg-cover bg-center ${broadcaster ? 'bg-fixed' : ''}`} style={{ backgroundImage: broadcaster ? `url(https://v1.vup.name/proxy/avatar?url=${broadcaster.background_image_url})` : '' }}></div>
      <div className="absolute inset-0 bg-black/10 backdrop-blur-lg"></div>
    <div className="flex justify-center items-center h-screen p-5 ">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title mb-4">舰长地址统计</h2>
          <input
            type="text"
            placeholder="请输入您的校验代码！"
            className="input input-bordered w-full "
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <div className="card-actions justify-end">
            <button className="btn btn-primary mt-4 w-full" onClick={handleSubmit}>确认</button>
          </div>
        </div>
      </div>
      </div>

    </div>
  );
};

export default InYag;
