import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './tailwind.css';
import './App.css';
import InYag from './pages/InYag';
import VerificationCodePage from './pages/VerificationCodePage';
import HomePage from './pages/HomePage';
import SongPage from './pages/SongPage';
import SendQuestion from './pages/SendQuestion';
import Info from './pages/Info';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:uid/verification" element={<InYag />} />
        <Route path="/:uid/verificationcode" element={<VerificationCodePage />} />
        <Route path="/:uid/song" element={<SongPage />} />
        <Route path="/:uid/send" element={<SendQuestion />} />
        <Route path="/:uid" element={<Info />} />
      </Routes>
    </Router>
  );
}

export default App;
