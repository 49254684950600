import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const VerificationCodePage = () => {
  const [data, setData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [formData, setFormData] = useState({
    contactPerson: '',
    name: '',
    Address: '',
    shippingAddress: '',
    contactPhone: ''
  });
  const [submitResponse, setSubmitResponse] = useState('');
  const location = useLocation();
  const verificationCode = new URLSearchParams(location.search).get('code');
  const [broadcaster, setBroadcaster] = useState(null);
  let { uid } = useParams();
  
  useEffect(() => {
    fetch(`https://v1.vup.name/api/v1/songlist?uid=${uid}`)
      .then((response) => response.json())
      .then((data) => {
        setBroadcaster(data.broadcaster);
        if (data.broadcaster && data.broadcaster.name) {
          document.title = data.broadcaster.name;
        }
      });
  }, [uid]);

  useEffect(() => {
    if (verificationCode) {
      fetch('https://v1.vup.name/captainInfo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ verificationcode: verificationCode }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            // 如果存在错误信息
            setShowAlert(true); // 显示弹窗
            setSubmitResponse(data.error); // 设置错误信息
          } else {
            setData(data); // 正常处理数据
          }
        })
        .catch(error => console.error('Error:', error));
    }
  }, [verificationCode]);
  useEffect(() => {
    let timer;
    if (submitResponse) {
      setShowAlert(true); // 显示弹出框
      setCountdown(3); // 重置倒计时
      timer = setInterval(() => {
        setCountdown(prevCount => {
          if (prevCount <= 1) {
            clearInterval(timer); // 停止定时器
            setShowAlert(false); // 关闭弹出框
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [submitResponse]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch('https://v1.vup.name/captainupdate', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...formData, verificationcode: verificationCode }),
    });
  
    const responseData = await response.json();
  
    // 检查是否存在特定的错误消息
    if (responseData.error && responseData.error === "校验码不存在") {
      setShowAlert(true); // 显示错误弹窗
      setSubmitResponse(responseData.error); // 设置错误信息以在弹窗中显示
    } else {
      setSubmitResponse(responseData.msg || responseData.error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
      <div className={`absolute inset-0 bg-cover bg-center min-h-screen ${broadcaster ? 'bg-fixed' : ''}`} style={{ backgroundImage: broadcaster ? `url(https://v1.vup.name/proxy/avatar?url=${broadcaster.background_image_url})` : '' }}>
      <div className="absolute inset-0 bg-black/10 backdrop-blur-lg">
      <div className="container mx-auto p-4 flex flex-col items-center">
      
        {data && (
          <form onSubmit={handleSubmit} className="mt-4 p-5 rounded-3xl  bg-white backdrop-blur-2xl shadow-2xl w-4/5 sm:w-3/5 md:w-4/5 lg:w-2/3 xl:w-7/8">
            <div className="flex items-center">
              <img
                src={`https://v1.vup.name/proxy/avatar?url=${data.liver_face}`}
                alt="Avatar"
                className="w-12 h-12 rounded-full"
              />
              <div className="ml-4">
                <h2 className="card-title">{data.liver_name}</h2>
                <p>{data.type_name}</p>
              </div>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">开始时间</span>
              </label>
              <input type="text" name="boarding_Time" className="input input-bordered" value={data.boarding_Time} onChange={handleChange} disabled/>
            </div>
            
            <div className="form-control">
              <label className="label">
                <span className="label-text">结束时间</span>
              </label>
              <input type="text" name="time" className="input input-bordered" value={data.time} onChange={handleChange} disabled/>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">UID</span>
              </label>
              <input type="text" name="uid" className="input input-bordered" value={data.uid} onChange={handleChange} disabled/>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">校验码</span>
              </label>
              <input type="text" name="verificationcode" className="input input-bordered" value={verificationCode} onChange={handleChange} disabled/>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">联系人（实名信息）</span>
              </label>
              <input type="text" name="contactPerson" className="input input-bordered" value={formData.contactPerson} onChange={handleChange} />
            </div>
            
            <div className="form-control">
              <label className="label">
                <span className="label-text">收件人（展示在快递单的）</span>
              </label>
              <input type="text" name="name" className="input input-bordered" value={formData.name} onChange={handleChange} />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">收件地址</span>
              </label>
              <input type="text" name="Address" className="input input-bordered" value={formData.Address} onChange={handleChange} />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">备用地址</span>
              </label>
              <input type="text" name="shippingAddress" className="input input-bordered" value={formData.shippingAddress} onChange={handleChange} />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">收件人手机号</span>
              </label>
              <input type="text" name="contactPhone" className="input input-bordered" value={formData.contactPhone} onChange={handleChange} />
            </div>

            <div className="form-control mt-4">
              <button type="submit" className="btn btn-neutral rounded-lg ">提交</button>
            </div>
          </form>
        )}

{showAlert && submitResponse && (
          <div className="mt-4 w-4/5 sm:w-3/5 md:w-4/5 lg:w-2/3 xl:w-7/8">
            <div role="alert" className="alert shadow-md flex justify-between items-center">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <span className="font-bold ml-2">{submitResponse}</span>
              </div>
              <button onClick={() => setShowAlert(false)} className="btn btn-sm btn-neutral">
                关闭（{countdown}）
              </button>
            </div>
          </div>
        )}
        
      </div>
    </div>
    </div>
  );
};

export default VerificationCodePage;
